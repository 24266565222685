import {Outlet} from 'react-router-dom';
import {Menu} from "../menu/Menu";
import Footer from "../footer/Footer";

export default function HomeLayout() {

    // const navigation = useNavigate();
    // useEffect(() => {
    //     let userId = localStorage.getItem("UserId");
    //     if (userId === null || userId === undefined || userId === "") {
    //         navigation('/');
    //     }
    // }, [navigation]);
    return (
        <div className={'dark_container'}>
            <Menu/>
            <main>
                <Outlet/>
            </main>
            <Footer/>
        </div>
    )
}